.icon-check-field {
    border: 1px solid black;
    width: 26px;
    padding: 0px 6px;
    margin: 5px 6px 5px 3px;
    height: 26px;

    &.no-box {
        border-width: 0;
    }

    &.btn-success {
        background-color: transparent;
        color: $success-main;
        border-color: $success-main;
    }

    &.btn-danger {
        background-color: transparent;
        color: $danger-main;
        border-color: $danger-main;
    }
}

td > .form-group.field > .form-group-icon-check-field {
    text-align: center;
}
