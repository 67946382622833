.rowProgress {
    font-size: 13px;
    padding: 4px;
}

.current_state {
    background-color: $secondary-main;
}

.complete_state {
    background-color: $secondary-main;

    &.is-last-state {
        background-color: $success-main;
    }
}

.next_state {
    background-color: $gray-100;
}
