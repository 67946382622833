// Copied from Bootstrap _variables
// Color system

// Material UI Color System
$white: #fff !default;
$gray-100: #f5f5f5 !default;
$gray-200: #eeeeee !default;
$gray-300: #e0e0e0 !default;
$gray-400: #bdbdbd !default;
$gray-500: #9e9e9e !default;
$gray-600: #757575 !default;
$gray-700: #616161 !default;
$gray-800: #424242 !default;
$gray-900: #212121 !default;
$black: #000 !default;

$grays: () !default;
$grays: map-merge(
    (
        '100': $gray-100,
        '200': $gray-200,
        '300': $gray-300,
        '400': $gray-400,
        '500': $gray-500,
        '600': $gray-600,
        '700': $gray-700,
        '800': $gray-800,
        '900': $gray-900,
    ),
    $grays
);

$blue: #2196f3 !default;
$indigo: #3f51b5 !default;
$purple: #673ab7 !default;
$pink: #e91e63 !default;
$red: #f44336 !default;
$orange: #ff9800 !default;
$yellow: #ffeb3b !default;
$green: #4caf50 !default;
$teal: #009688 !default;
$cyan: #00bcd4 !default;
// End of Material UI Color System

// BxThemeProvider Colors
// Colors
$primary-main: #091f68;
$secondary-main: #9dcbef;
$info-main: #3c8ef7;
$warning-main: #f8d161;
$success-main: #b8e09f;
$danger-main: #912119;
$danger-main-bg: #f0776e;

// Background colors
$background-default: #fafafa;

// Theme palette
$zebra-list-item-1: #f0efee;

// Additional colors
$delta-red: #a15751; // HeatMapCell
$delta-green: #bed6b0; // HeatMapCell
$red2: #bd8986; // Progress Bar

// End of BxThemeProvider Colors

$sla-red: #ff0000;
$sla-green: #00ff00;
$sla-yellow: #ffff00;

$border-radius: 0.125rem;

// form style
$inline-input-gap: 1.5rem;
$inline-label-gap: 0.5rem;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1780px,
);

$sidebar-button-width: 3.5rem;
$sidebar-content-width: 3.5rem;

$main-container-gap: 24px;

// navbar
$navbar-height: 4rem;
