.form-comparison-container {
    position: relative;

    > table {
        tr.field-row > td {
            border: 1px solid black;
            background-color: $primary-main;
            color: $white;
        }

        td.field-prop-cell {
            border: 1px solid black;
            padding-left: 1em;
            padding-right: 1em;
            background-color: $white;
        }

        td.value-cell {
            border: 1px solid black;
            background-color: $white;

            &.is-empty {
                background-color: #c0c0c0c0;
            }
        }
    }

    .add-form-panel {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        right: 0;
        top: $navbar-height;

        > .expand-section {
            background-color: $white;
            border-radius: $border-radius;
            max-height: 70vh;
            overflow-y: auto;

            > .form-component {
                > .card > .card-body {
                    padding-left: 1em;
                    padding-right: 1em;
                }

                #root__title_ns {
                    font-size: medium;
                }
            }

            > .section {
                > .title {
                    font-size: medium;
                    font-weight: bold;
                }
                padding-left: 1em;
                padding-right: 1em;
            }
        }
    }
}
