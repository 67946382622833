// xs, sm, md, lg, xl

.form-details {
    .card-header {
        background-color: $primary-main;
        color: $white;
    }
}

@include media-breakpoint-up(xxl) {
    .form-details {
        position: fixed;
        top: 8.7rem;
        right: 0.5rem;
        max-width: 320px;
        width: 100%;
        border-radius: 0.5rem;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(100vh - 200px);
        $padding: 0.5rem;

        .card-header,
        .card-body {
            padding-top: $padding;
            padding-bottom: $padding;
        }
    }
}

@include media-breakpoint-between(md, xl) {
    .form-details {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, auto);
        grid-auto-flow: column;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        margin-bottom: 1rem;
    }
}

@include media-breakpoint-down(sm) {
    .form-details {
        .card-header:not(:first-child) {
            background-color: $secondary-main;
        }
    }
}
