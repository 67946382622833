// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900

html body {
    font-family: 'Inter', sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-size: 12pt;
}

/* App main containers are always full viewport size */
html,
body,
#app-root,
.app-layout {
    width: 100%;
    height: 100vh;
}

body {
    background-color: $gray-100 !important;
}

a.nav-link {
    cursor: pointer;
}

.dont-show-component {
    display: none;
}

legend {
    font-weight: bold;
    font-size: 1.75rem;
}

a {
    color: $info-main;
}

.field-description {
    font-weight: bold;
    font-size: 1.4rem;
    font-style: italic;
}

.control-label {
    font-weight: bold;
    font-size: large;
    margin-bottom: 1rem;
}

input[type='radio'] {
    margin-right: 0.75rem;
}

#root__title {
    margin-bottom: 1.25rem;
    font-weight: bold;
}

.form-title {
    text-align: center;
    margin-bottom: 2rem;
}

.step-circle {
    background: $primary-main;
    margin: 0px auto 0.5rem auto;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.step-title {
    color: $primary-main;
    opacity: 0.5;

    &.active {
        opacity: 1;
        font-weight: 700;
    }
}

.field-radio-group {
    display: flex;
    flex-wrap: wrap;

    .radio {
        margin-right: 1rem;
    }

    .radio-inline {
        margin-right: 1rem;
    }
}

input[type='checkbox'] {
    margin-right: 1rem;
}

.card.clickable {
    &:hover {
        background: $gray-100;
        cursor: pointer;
    }
}

.sort-button {
    white-space: nowrap;
}

.form-submit-buttons {
    > :not(:first-child) {
        margin-left: 45px;
    }
}

div.is-a-p {
    margin-bottom: 1rem;
}

nav > .collapse > ul.navbar-nav:not(:last-child) {
    flex-grow: 1;
}

.nowrap {
    white-space: nowrap;
}

.banner-heading {
    background-color: $secondary-main;
    color: $white;
    box-sizing: content-box;
}

.no-margin {
    margin: 0 !important;
}

.btn-submit {
    background-color: $primary-main;
    color: $white;
}

.btn-save {
    background-color: $primary-main;
    color: $white;
}
