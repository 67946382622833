.json-tree-editor {
    $grid-columns: 150px 32px 1fr 6rem;
    position: relative;
    display: grid;
    grid-template-columns: 32px 1fr;
    background-color: white;
    border: 1px solid black;

    &.no-root {
        grid-template-columns: $grid-columns;
    }

    .json-tree-editor-node-object,
    .json-tree-editor-node-array {
        position: relative;
        grid-column-start: 1;
        grid-column-end: 5;
        display: grid;
        grid-template-columns: $grid-columns;
        border-left: 1px solid black;
        padding-left: 12px;
    }

    label.switch {
        margin-bottom: 0;
        justify-self: center;
    }

    .json-tree-editor-node {
        > input[type='text'] {
            width: auto;
        }
    }

    .type-selector {
        > select {
            width: 100%;
            height: 100%;
        }
    }

    .index {
        grid-column-start: 1 !important;
        position: relative;
        > input {
            width: 100%;
            padding-right: 26px;
        }
        > .number {
            text-align: right;
            padding-right: 26px;
            &::before {
                content: '#';
            }
        }
        > .btn {
            &.delete-btn {
                position: absolute;
                top: 4px;
                right: 2px;
                font-size: 0.8rem;
                padding: 0 5px;
                margin-left: 0;
                line-height: 1em;
            }
        }
    }

    .lower-buttons {
        grid-column-start: 1 !important;
        text-align: right;
        .btn {
            font-size: 0.8rem;
            line-height: 1em;
            padding: 5px 20px;
        }
    }

    .buttons {
        display: flex;
        flex-direction: row;
        &:not(.inplace) {
            grid-column-start: 4 !important;
        }
        align-items: flex-start;
        .btn {
            > .fa {
                font-size: inherit;
            }
            font-size: 0.6rem;
            line-height: 1em;
            width: 2.5em;
            padding: 5px 5px;
        }
    }

    .item-count {
        padding-left: 5px;
    }

    input {
        border: 1px solid black;
    }

    .number-editor {
        position: relative;
        > input {
            padding-left: 12px;
            text-align: right;
            width: 100%;
            padding-right: 12px;
        }
        > .marker {
            position: absolute;
            top: 5px;
            left: 5px;
            color: red;
        }
    }
}

* .json-type-select.dropdown {
    line-height: 1em;
    padding: 0;
    border-width: 0;

    > .dropdown-toggle {
        line-height: 1em;
        width: 100%;
        padding: 4px 0;
        position: relative;
        background-color: transparent;
        border-radius: 0;
        border-color: black;
        > i {
            position: relative;
            left: -0.1em;
        }
        &::after {
            position: absolute;
            top: 0.8em;
            right: 0.1em;
            font-size: 0.83em;
        }
        &:focus {
            outline: none;
        }
    }

    > .dropdown-menu {
        min-width: auto;
        > .dropdown-item {
            padding: 0.25rem;

            &.active {
                background-color: $info-main;
            }
        }
    }
}
