.log-entry {
    margin-top: 2px;
}

table.log-table {
    width: 100%;
    > thead > tr > th {
        text-align: center;
    }
    > tbody > tr {
        cursor: default;
        border: 1px solid gray;
        > td {
            padding-left: 1em;
            padding-right: 1em;
            > .idx {
                text-align: center;
            }
        }
        &:not(.selected):not(.selected-expanded):hover {
            background-color: $info-main;
        }
        &.selected {
            background-color: $info-main;
            border-bottom: 0;

            > td {
                font-weight: bold;
            }
        }
        &.selected-expanded {
            cursor: inherit;
            background-color: rgba($info-main, 0.3);
            border-top: 0;

            &:not(.last) {
                border-bottom: 0;
            }

            &.last {
                padding-bottom: 1em;
            }
        }
    }
}

.log-entry:last-child {
    margin-top: 32px;
}

.log-entry .card-header {
    position: relative;
}

.log-entry .card-header > .action {
    position: absolute;
    right: 15px;
    font-size: 0.7em;
}

.log-entry .card-header > .index {
    display: inline-block;
    min-width: 2em;
    text-align: center;
    padding-right: 1em;
}

.log-entry .card-header > .status {
    display: inline-block;
}

.log-entry .card-header > .status {
    display: inline-block;
}

.log-entry .state,
table.log-table .state {
    font-family: monospace;
    font-size: 0.8em;
    background-color: rgba(128, 128, 128, 0.1);
    white-space: pre;
}

.log-entry .timestamp {
    font-size: 0.9em;
}

.uweentity-history-list {
    margin: -20px;
    > .entry {
        padding: 5px 20px;

        &:not(:last-child) {
            border-bottom: 1px solid $gray-100;
        }

        &:nth-of-type(odd) {
            background-color: $gray-200;
        }

        &:last-child {
            border-bottom-left-radius: 15px;
        }

        .timestamp {
            font-size: 0.7rem;
            font-weight: bold;
        }
        .username {
            font-size: 0.7rem;
            color: $gray-500;
        }
        .action {
            font-size: 0.85rem;
            color: $black;
        }
        .option {
            font-size: 0.75rem;
            color: $black;
        }
        .reason {
            border: 1px solid $gray-500;
            font-size: 0.85rem;
            color: $black;
            padding: 3px 5px;
        }
    }
}

.uweentity-history-expanded {
    .uweentity-history {
        .date-time {
            font-size: small;
            font-weight: bold;
        }

        .text {
            white-space: pre-wrap;
        }
    }
}
