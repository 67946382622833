#feedbacklabel.feedbacklab {
    #feedbacklabelspan {
        display: none;
    }
}

#zsfeedbackwidgetdiv {
    #zsfbform #feedbPopupSbmtBtn {
        box-shadow: none;
        box-sizing: border-box;
        scroll-margin-top: 6rem;
        font-family: sans-serif;
        text-decoration: none;
        display: inline-block;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 0.25rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        color: $white;
        background-color: $primary-main;
        border-color: $primary-main;
        cursor: pointer;

        &:hover {
            background-color: #d2121d;
            border-color: #c6111b;
        }
    }
}
