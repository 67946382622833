.system-setting-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .button-item {
        min-width: 12rem;
        max-width: 17rem;
    }
}
