.editor-container {
    // background-color: red;
    overflow: hidden;
    position: relative;
    margin: 0 -21px 0 -10px;

    > .editor-main-container {
        position: absolute;
        top: 0;
        height: 100%;
        left: 0;
        overflow: auto;
        // background-color: purple;
    }

    > .editor-sidebar {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        margin-left: 3px;
        overflow-x: hidden;
        overflow-y: auto;
        border-radius: $border-radius $border-radius 0 0;
        background-color: $white;
        > .sidebar-panel {
            position: relative;
            //     margin: 0 10px;
        }
    }

    > .adjuster-edge {
        z-index: 100;
        position: absolute;

        &:hover {
            background-color: $primary-main;
            transition: background-color 2s;
            transition-timing-function: ease-out;
        }

        &.active {
            background-color: $primary-main;
        }

        &.horizontal {
            left: 0;
            right: 0;
            height: 3px;
            cursor: ns-resize;
        }

        &.vertical {
            top: 0;
            bottom: 0;
            width: 3px;
            cursor: ew-resize;
        }
    }
}

.tree-view {
    > .title {
        display: flex;
        justify-content: space-between;
        position: relative;

        > .buttons {
            > .btn {
                line-height: 1em;
                padding: 2px;
            }
        }
    }
    &.selected {
        > .title {
            // box-shadow: 0 0 3pt 2pt cornflowerblue;
            background-color: cornflowerblue;
            color: white;
        }
    }
}

.draggable-list-item {
    position: relative;
    i.fa {
        padding-right: 5px;
        cursor: pointer;
    }
}

.dropzone-targets {
    $hsize: 10%;
    $vsize: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    > * {
        position: absolute;
    }

    &.has-left {
        > .top {
            left: $hsize;
        }
        > .bottom {
            left: $hsize;
        }
    }
    &.has-right {
        > .top {
            right: $hsize;
        }
        > .bottom {
            right: $hsize;
        }
    }

    > .top {
        top: 0;
        left: 0;
        right: 0;
        height: $vsize;
    }
    > .bottom {
        bottom: 0;
        left: 0;
        right: 0;
        height: $vsize;
    }
    > .left {
        top: 0;
        bottom: 0;
        left: 0;
        width: $hsize;
    }
    > .right {
        top: 0;
        bottom: 0;
        right: 0;
        width: $hsize;
    }

    &.visible {
        > .top {
            background-color: rgba(255, 0, 0, 0.35);
        }
        > .bottom {
            background-color: rgba(0, 255, 0, 0.35);
        }
        > .left {
            background-color: rgba(0, 0, 255, 0.35);
        }
        > .right {
            background-color: rgba(255, 255, 0, 0.35);
        }
    }
}
