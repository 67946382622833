header {
    height: $navbar-height;
}

nav.navbar {
    background-color: $white;
    color: $primary-main;
    height: $navbar-height;
    border-bottom: 1px solid $gray-100;
    box-shadow: inset 0px -2px 5px rgba(0, 0, 0, 0.1);

    .nav-icon {
        font-size: 1.25rem;
        color: $primary-main;

        .icon-badge {
            color: $white;
        }
    }

    .clock.nav-clock {
        font-size: 1.75rem;
        margin-right: 1rem;
    }

    .nav.navbar-nav {
        > .navbar-text {
            display: flex;
            align-items: center;
            padding-left: 5px;
            padding-right: 5px;
        }

        > .nav-item {
            display: flex;
            align-items: center;
        }
    }

    a.nav-link {
        color: $gray-500;
    }

    .navbar-help-text {
        color: $primary-main;
    }

    .navbar-text.text-title {
        color: $primary-main;
        font-weight: bold;
    }
}

.medium-panel {
    width: 350px;
    max-height: 550px;
    overflow-y: auto;
}

.tooltip > div.tooltip-inner {
    background-color: $white !important;
    color: $primary-main !important;
    margin-left: -9px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-weight: bold;
}

.bs-tooltip-auto[x-placement^='right'] span.arrow::before,
.bs-tooltip-right .arrow::before {
    border-right-color: $white;
    opacity: 0%;
}

.nav-notification-mobile {
    display: none;
}
