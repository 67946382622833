.uweentity-form-container {
    position: relative;
}

.stamp-across {
    color: $primary-main;
    border-top: 1px solid $primary-main;
    border-bottom: 1px solid $primary-main;
    text-align: center;
}

@include media-breakpoint-up(sm) {
    .stamp-across {
        left: 50%;
        top: 50%;
        width: 7em;
        line-height: 1.5em;
        position: fixed;
        z-index: 999;
        font-size: 72pt;
        text-shadow: 9px 9px 20px #000000;
        transform: translate(-50%, -50%);
    }
}

@include media-breakpoint-down(xs) {
    .stamp-across {
        font-size: 25pt;
    }
}
