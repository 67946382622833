.nav.nav-tabs {
    $color: $primary-main;
    $text-color: $white;

    .nav-item {
        &.side-right {
            margin-left: auto;
            & ~ .side-right {
                margin-left: unset;
            }
        }
    }

    .nav-link {
        &:not(.active) {
            border-color: $border-color;
            color: $color;
        }
        &:hover {
            background: transparentize($color, 0.95);
        }
        &.active {
            border-color: $color;
            color: $white;
            background-color: $color;
        }
    }
}
