.form-control.composite-input-field {
    display: flex;
    flex-direction: column;
    height: auto;

    &.readonly {
        background-color: $gray-100;
    }

    > .composite-input-field-row {
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        > .field {
            position: relative;
            flex-grow: 1;
            > input {
                background-color: $gray-100;
                border: none;
                max-width: none;
                margin: 2px;
                padding-top: 3px;
                padding-bottom: 3px;
                line-height: 1em;
                height: 1em;
                padding-left: 3px;
                padding-right: 3px;
            }

            .error-tag {
                position: absolute;
                right: 8px;
                top: 0px;

                &::before {
                    color: $red;
                    content: '\2022';
                }
            }
        }
    }
}
